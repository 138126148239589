import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from './Toast';
import './TimeStampConverter.css';
import { useGoogleAnalytics } from '../hooks/useGoogleAnalytics';

const TimeStampConverter: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();

  // 状态管理
  const [currentTimestamp, setCurrentTimestamp] = useState<number>(Math.floor(Date.now() / 1000));
  const [timestampUnit, setTimestampUnit] = useState<'seconds' | 'milliseconds'>('seconds');
  const [timezone, setTimezone] = useState<string>('Asia/Shanghai');
  
  // 时间戳转日期相关状态
  const [inputTimestamp, setInputTimestamp] = useState<string>('');
  const [convertedDate, setConvertedDate] = useState<string>('');
  
  // 日期转时间戳相关状态
  const [inputDate, setInputDate] = useState<string>('');
  const [convertedTimestamp, setConvertedTimestamp] = useState<string>('');

  // Toast 状态
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  // 更新当前时间戳
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTimestamp(Math.floor(Date.now() / 1000));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // 复制功能
  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setToastMessage(t('toast.copied'));
      setShowToast(true);
    } catch (err) {
      setToastMessage(t('toast.copyFailed'));
      setShowToast(true);
    }
  };

  // 时间戳转日期
  const handleTimestampToDate = () => {
    try {
      if (!inputTimestamp) {
        alert(t('timestampToDate.input'));
        return;
      }

      const timestamp = parseInt(inputTimestamp);
      if (isNaN(timestamp)) {
        alert(t('timestampToDate.invalidInput'));
        return;
      }

      const milliseconds = timestampUnit === 'seconds' ? timestamp * 1000 : timestamp;
      const date = new Date(milliseconds);

      if (date.toString() === 'Invalid Date') {
        alert(t('timestampToDate.invalidTimestamp'));
        return;
      }

      // 格式化日期
      const formattedDate = date.toLocaleString(
        i18n.language === 'zh' ? 'zh-CN' : 'en-US',
        {
          timeZone: timezone,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }
      );

      setConvertedDate(formattedDate);

      // 跟踪转换事件
      trackEvent(
        'conversion',
        'timestamp',
        'timestamp_to_date'
      );
    } catch (error) {
      alert(t('timestampToDate.error'));
    }
  };

  // 日期转时间戳
  const handleDateToTimestamp = () => {
    try {
      if (!inputDate) {
        alert(t('dateToTimestamp.input'));
        return;
      }

      const date = new Date(inputDate);
      if (date.toString() === 'Invalid Date') {
        alert(t('dateToTimestamp.invalidDate'));
        return;
      }

      const timestamp = timestampUnit === 'seconds' 
        ? Math.floor(date.getTime() / 1000)
        : date.getTime();

      setConvertedTimestamp(timestamp.toString());
    } catch (error) {
      alert(t('dateToTimestamp.error'));
    }
  };

  return (
    <div className="converter-wrapper">
      <div className="converter-container">
        <div className="converter-header">
          <div className="current-time">
            <span className="time-label">{t('currentTime')}:</span>
            <span className="time-value">{currentTimestamp}</span>
            <button 
              className="copy-button small"
              onClick={() => handleCopy(currentTimestamp.toString())}
            >
              {t('timestampToDate.copy')}
            </button>
          </div>
          
          <div className="unit-selector">
            <label className="unit-label">{t('unit')}:</label>
            <div className="toggle-group">
              <button
                className={`toggle-button ${timestampUnit === 'seconds' ? 'active' : ''}`}
                onClick={() => setTimestampUnit('seconds')}
              >
                {t('seconds')}
              </button>
              <button
                className={`toggle-button ${timestampUnit === 'milliseconds' ? 'active' : ''}`}
                onClick={() => setTimestampUnit('milliseconds')}
              >
                {t('milliseconds')}
              </button>
            </div>
          </div>

          <div className="timezone-selector">
            <label className="timezone-label">{t('timezone')}:</label>
            <select 
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              className="timezone-select"
            >
              <option value="Asia/Shanghai">{t('asiaShanghai')}</option>
              <option value="UTC">UTC</option>
              <option value="America/New_York">{t('americaNewYork')}</option>
              <option value="Europe/London">{t('europeLondon')}</option>
            </select>
          </div>
        </div>

        <div className="converter-grid">
          <div className="converter-box">
            <h3>{t('timestampToDate.title')}</h3>
            <div className="input-row">
              <input
                type="text"
                value={inputTimestamp}
                onChange={(e) => setInputTimestamp(e.target.value)}
                placeholder={t('timestampToDate.input')}
                className="converter-input"
              />
              <button 
                className="convert-button"
                onClick={handleTimestampToDate}
              >
                {t('timestampToDate.convert')}
              </button>
            </div>
            {convertedDate && (
              <div className="result-row">
                <span className="result-text">{convertedDate}</span>
                <button 
                  className="copy-button small"
                  onClick={() => handleCopy(convertedDate)}
                >
                  {t('timestampToDate.copy')}
                </button>
              </div>
            )}
          </div>

          <div className="converter-box">
            <h3>{t('dateToTimestamp.title')}</h3>
            <div className="input-row">
              <input
                type="datetime-local"
                value={inputDate}
                onChange={(e) => setInputDate(e.target.value)}
                className="converter-input"
              />
              <button 
                className="convert-button"
                onClick={handleDateToTimestamp}
              >
                {t('dateToTimestamp.convert')}
              </button>
            </div>
            {convertedTimestamp && (
              <div className="result-row">
                <span className="result-text">{convertedTimestamp}</span>
                <button 
                  className="copy-button small"
                  onClick={() => handleCopy(convertedTimestamp)}
                >
                  {t('dateToTimestamp.copy')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="documentation">
        <h2>{t('documentation.title')}</h2>
        
        <section className="doc-section">
          <h3>{t('documentation.whatIsTimestamp.title')}</h3>
          <p>{t('documentation.whatIsTimestamp.content')}</p>
        </section>

        <section className="doc-section">
          <h3>{t('documentation.unixTimestamp.title')}</h3>
          <p>{t('documentation.unixTimestamp.content')}</p>
          <ul>
            <li>{t('documentation.unixTimestamp.point1')}</li>
            <li>{t('documentation.unixTimestamp.point2')}</li>
            <li>{t('documentation.unixTimestamp.point3')}</li>
          </ul>
        </section>

        <section className="doc-section">
          <h3>{t('documentation.commonUsage.title')}</h3>
          <p>{t('documentation.commonUsage.content')}</p>
          <ul>
            <li>{t('documentation.commonUsage.point1')}</li>
            <li>{t('documentation.commonUsage.point2')}</li>
            <li>{t('documentation.commonUsage.point3')}</li>
            <li>{t('documentation.commonUsage.point4')}</li>
          </ul>
        </section>

        <section className="doc-section">
          <h3>{t('documentation.timeZones.title')}</h3>
          <p>{t('documentation.timeZones.content')}</p>
        </section>

        <section className="doc-section">
          <h3>{t('documentation.bestPractices.title')}</h3>
          <ul>
            <li>{t('documentation.bestPractices.point1')}</li>
            <li>{t('documentation.bestPractices.point2')}</li>
            <li>{t('documentation.bestPractices.point3')}</li>
            <li>{t('documentation.bestPractices.point4')}</li>
          </ul>
        </section>

        <section className="doc-section">
          <h3>{t('documentation.faq.title')}</h3>
          <div className="faq-list">
            <div className="faq-item">
              <h4>{t('documentation.faq.q1')}</h4>
              <p>{t('documentation.faq.a1')}</p>
            </div>
            <div className="faq-item">
              <h4>{t('documentation.faq.q2')}</h4>
              <p>{t('documentation.faq.a2')}</p>
            </div>
            <div className="faq-item">
              <h4>{t('documentation.faq.q3')}</h4>
              <p>{t('documentation.faq.a3')}</p>
            </div>
            <div className="faq-item">
              <h4>{t('documentation.faq.q4')}</h4>
              <p>{t('documentation.faq.a4')}</p>
            </div>
          </div>
        </section>
      </div>
      
      <Toast 
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
    </div>
  );
};

export default TimeStampConverter;