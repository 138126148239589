import React from 'react';
import { useTranslation } from 'react-i18next';
import TimeStampConverter from './components/TimeStampConverter';
import LanguageSelector from './components/LanguageSelector';
import './App.css';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';

// 将结构化数据移到组件外部
const structuredData = {
  "@context": "https://schema.org",
  "@type": "WebApplication",
  "name": "Timestamp Converter",
  "applicationCategory": "Utility",
  "operatingSystem": "Any",
  "offers": {
    "@type": "Offer",
    "price": "0",
    "priceCurrency": "USD"
  },
  "featureList": [
    "Unix timestamp to date conversion",
    "Date to Unix timestamp conversion",
    "Multiple timezone support",
    "Millisecond precision"
  ]
};

function App() {
  const { t } = useTranslation();
  useGoogleAnalytics();

  return (
    <div className="App">
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>

      <LanguageSelector />
      <header className="App-header">
        <h1>{t('title')}</h1>
        <p>{t('description')}</p>
      </header>

      <main className="App-main">
        <TimeStampConverter />
      </main>

      <footer className="App-footer">
        <nav className="footer-links">
          <a href="/about">{t('footer.about')}</a>
          <a href="/privacy">{t('footer.privacy')}</a>
          <a href="/terms">{t('footer.terms')}</a>
        </nav>
        <p className="copyright">{t('footer.copyright', { year: new Date().getFullYear() })}</p>
      </footer>
    </div>
  );
}

export default App;
