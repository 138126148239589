import { useEffect } from 'react';

// 声明全局 gtag 函数
declare global {
  interface Window {
    gtag: (
      command: string,
      action: string,
      params?: any
    ) => void;
    dataLayer: any[];
  }
}

export const useGoogleAnalytics = () => {
  useEffect(() => {
    // 页面加载时发送页面浏览事件
    if (typeof window.gtag !== 'undefined') {
      window.gtag('config', 'G-H7EHR7TQBF', {
        page_path: window.location.pathname + window.location.search
      });
    }
  }, []);

  // 用于手动跟踪事件的函数
  const trackEvent = (
    eventName: string,
    category: string,
    label: string,
    value?: number
  ) => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', eventName, {
        event_category: category,
        event_label: label,
        value: value
      });
    }
  };

  return { trackEvent };
}; 